import { fetchCourses } from '../api/courses';
import AsyncComponent from '../components/AsyncComponent';
import Chrome from '../components/Chrome';
import CourseListing from '../components/courses/CourseListing';

function MyCourses({ courses }) {
  const breadcrumbs = [{ text: 'My Courses' }];

  return (
    <>
      <Chrome title="My Courses" breadcrumbs={breadcrumbs}>
        <CourseListing courses={courses} />
      </Chrome>
    </>
  );
}

function MyCoursesScreen() {
  /* -- Hooks -- */
  return (
    <AsyncComponent
      executeQuery={fetchCourses}
      cacheKey="courses"
      render={(courses) => <MyCourses courses={courses} />}
    />
  );
}

export default MyCoursesScreen;
