import { Alert, Col, Row } from '@bootstrap-styled/v4';
import { BoxedPrimaryButton } from '../components/base/Buttons';
import { CheckCircle, FileEarmarkFont } from '@styled-icons/bootstrap';
import { fetchCourses, markLessonComplete } from '../api/courses';
import { H2, H3 } from '../components/base/Headers';
import { useMutation, useQueryCache } from 'react-query';
import { useParams } from 'react-router';
import AsyncComponent from '../components/AsyncComponent';
import Chrome from '../components/Chrome';
import CommentEditor from '../components/discussion/CommentEditor';
import CommentStream from '../components/discussion/CommentStream';
import md5 from 'md5';
import moment from 'moment';
import NotFound from './NotFound';
import styled from 'styled-components';
import VideoPlayer from '../components/VideoPlayer';

const TimeAgo = styled.p`
  color: var(--neutral);
  font-size: 1rem;
  font-style: italic;
`;

const Description = styled.p`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const DiscussionHeader = styled(H3)`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const DiscussionContainer = styled.div`
  margin-top: 3rem;
  position: relative;
`;

const CommentListContainer = styled.div`
  width: 100%;
`;

const EditorContainer = styled.div`
  width: 100%;
`;

const ResourceItem = styled.div`
  padding-top: 0.5rem;
`;

const ResourceContainer = styled.div`
  margin-top: 2rem;
`;

const SystemMessageContainer = styled.div`
  padding-top: 1rem;
`;

function MarkAsDoneButton({ course, unit, lesson }) {
  const cache = useQueryCache();
  const markAsDoneParams = {
    courseId: course.key,
    unitId: unit.key,
    lessonId: lesson.key,
  };

  const [markAsDone, { isLoading }] = useMutation(() => markLessonComplete(markAsDoneParams), {
    onSuccess: () => {
      cache.cancelQueries('courses');
      cache.setQueryData('courses', (old) => {
        if (!old) {
          return old;
        }

        const _course = old.find((_) => _.key === course.key);
        if (!_course) {
          return old;
        }

        const _unit = course.units.find((_) => _.key === unit.key);
        if (!_unit) {
          return old;
        }

        const _lesson = unit.lessons.find((_) => _.key === lesson.key);
        if (!_lesson) {
          return old;
        }

        _lesson.done = true;
        return old;
      });
    },
  });

  if (lesson.done) {
    return null;
  }

  const clickMarkAsDone = (e) => {
    e.preventDefault();
    markAsDone();
  };

  return (
    <BoxedPrimaryButton onClick={clickMarkAsDone} disabled={isLoading}>
      Mark As Done
    </BoxedPrimaryButton>
  );
}

function VideoContent({ unit, lesson }) {
  const videoOptions = {
    autoplay: true,
    controls: true,
    sources: [
      {
        src: lesson.url,
        type: 'video/mp4',
      },
    ],
  };

  return <VideoPlayer {...videoOptions} />;
}

function ResourceList({ lesson }) {
  return !lesson || !lesson.links || !lesson.links.length ? null : (
    <ResourceContainer>
      <p>(mentioned links on pdf)</p>
      <h3>Resources</h3>
      {lesson.links.map((link) => {
        return (
          <ResourceItem key={link.url}>
            <FileEarmarkFont size="12" />{' '}
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.text}
            </a>
          </ResourceItem>
        );
      })}
    </ResourceContainer>
  );
}

function LessonContent({ course, unit, lesson }) {
  const contextId = md5(`${course.key}:${unit.key}:${lesson.key}`);
  let suffix = null;
  if (lesson.done) {
    suffix = <CheckCircle size={14} />;
  }

  if (lesson.expired) {
    return (
      <SystemMessageContainer>
        <Alert color="danger" uncontrolled>
          <p>Your access to this lesson has expired.</p>
        </Alert>
      </SystemMessageContainer>
    );
  }

  if (!lesson.isAvailable) {
    return (
      <SystemMessageContainer>
        <Alert color="info" uncontrolled>
          <p>This lesson premieres on {moment(lesson.releaseDate).format('MM/DD/YYYY')}.</p>
        </Alert>
      </SystemMessageContainer>
    );
  }

  return (
    <>
      <Row>
        <Col md={4}>
          <H2>
            {lesson.title} {suffix}
          </H2>
          <TimeAgo>{moment(lesson.releaseDate).fromNow()}</TimeAgo>
          <Description>{lesson.description}</Description>
          <MarkAsDoneButton course={course} unit={unit} lesson={lesson} />
          <ResourceList lesson={lesson} />
        </Col>
        <Col md={8}>
          <VideoContent lesson={lesson} unit={unit} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <DiscussionContainer>
            <DiscussionHeader>Discussion</DiscussionHeader>
            <EditorContainer>
              <CommentEditor contextId={contextId} />
            </EditorContainer>
            <CommentListContainer>
              <CommentStream contextId={contextId} />
            </CommentListContainer>
          </DiscussionContainer>
        </Col>
      </Row>
    </>
  );
}

function ViewLesson({ course, unit, lesson }) {
  const breadcrumbs = [
    { text: 'My Courses', url: '/my-courses' },
    { text: course.title, url: `/my-courses/${course.key}` },
    { text: unit.title, url: `/my-courses/${course.key}/units/${unit.key}` },
    { text: lesson.title },
  ];

  return (
    <>
      <Chrome title={course.title} breadcrumbs={breadcrumbs}>
        <LessonContent course={course} unit={unit} lesson={lesson} />
      </Chrome>
    </>
  );
}

function ViewLessonScreen() {
  /* -- Hooks -- */
  const { courseId, unitId, lessonId } = useParams();
  return (
    <AsyncComponent
      executeQuery={fetchCourses}
      cacheKey="courses"
      render={(courses) => {
        const course = courses.find((_) => _.key === courseId);
        if (!course) {
          return <NotFound />;
        }

        const unit = course.units.find((_) => _.key === unitId);
        if (!unit) {
          return <NotFound />;
        }

        const lesson = unit.lessons.find((_) => _.key === lessonId);
        if (!lesson) {
          return <NotFound />;
        }

        return <ViewLesson course={course} unit={unit} lesson={lesson} />;
      }}
    />
  );
}

export default ViewLessonScreen;
