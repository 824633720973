import { deleteComment, fetchComments } from '../../api/comments';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { WaveLoading } from 'styled-spinkit';
import QueryStatus from '../../utils/QueryStatus';
import Stream from './Stream';

function CommentStream({ contextId }) {
  /* -- Hooks -- */
  const { data: stream, status } = useQuery(['comments', contextId], () =>
    fetchComments({ contextId }),
  );

  const cache = useQueryCache();
  const [onLoadNext, { isLoading }] = useMutation(
    () => fetchComments({ contextId, next: stream.next }),
    {
      onSuccess: (newStream) => {
        cache.cancelQueries(['comments', contextId]);
        cache.setQueryData(['comments', contextId], (old) => {
          if (!old) {
            return old;
          }

          return {
            ...newStream,
            items: [...old.items, ...newStream.items],
          };
        });
      },
    },
  );

  const [deleteItem, { isDeleting }] = useMutation(
    (item) => deleteComment({ contextId, commentId: item.id }),
    {
      onSuccess: (commentId) => {
        cache.cancelQueries(['comments', contextId]);
        cache.setQueryData(['comments', contextId], (old) => {
          if (!old) {
            return old;
          }

          return {
            ...old,
            items: old.items.filter((_) => _.id !== commentId),
          };
        });
      },
    },
  );

  /* -- Event Handlers -- */
  /* -- Rendering -- */
  switch (status) {
    case QueryStatus.ERROR:
      return <div>error</div>;

    case QueryStatus.LOADING:
      return <WaveLoading color="var(--primary)" size={40} />;

    default:
      return (
        <Stream
          contextId={contextId}
          deleteItem={deleteItem}
          isDeleting={isDeleting}
          stream={stream}
          isLoading={isLoading}
          onLoadNext={onLoadNext}
        />
      );
  }
}

export default CommentStream;
