import AsyncSelect from 'react-select/async';
import I18n from './I18n';
import localize from '../../utils/localize';
import Select from 'react-select';

const customStyles = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? 'var(--primary--active)' : 'var(--input-border-color)',
    boxShadow: state.isFocused ? '0 0 2px 0 var(--primary--active)' : 'none',
    height: 'auto',
    minHeight: '30px',
    width: 200,
    ':hover': {
      ...base[':active'],
      borderColor: 'var(--primary--hover)',
      boxShadow: '0 0 2px 0 var(--primary--hover)',
    },
  }),

  indicatorsContainer: (base) => ({
    ...base,
    height: 'auto',
  }),

  menu: (base) => ({
    ...base,
    zIndex: 10,
  }),
};

function SelectPicker(props) {
  return (
    <Select noOptionsMessage={() => <I18n token="No Options" />} styles={customStyles} {...props} />
  );
}

function AsyncSelectPicker(props) {
  return (
    <AsyncSelect
      placeholder={`${localize('Type to search')}...`}
      styles={customStyles}
      {...props}
    />
  );
}

export default SelectPicker;
export { AsyncSelectPicker };
