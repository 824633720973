import { Card, CardBlock, CardImg, CardText, Col } from '@bootstrap-styled/v4';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import styled from 'styled-components';

const ListingCard = styled(Card)`
  margin-bottom: 2rem;
`;

export default function LessonListingItem({ course, unit, lesson }) {
  return (
    <Col md={4}>
      <ListingCard>
        <Link to={`/my-courses/${course.key}/units/${unit.key}/lessons/${lesson.key}`}>
          <CardImg top width="100%" src={lesson.thumbnail} />
        </Link>
        <CardBlock>
          <CardText>{parse(lesson.description || '')}</CardText>
        </CardBlock>
      </ListingCard>
    </Col>
  );
}
