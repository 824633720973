import { H3 } from '../components/base/Headers';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import SplashChrome from '../components/SplashChrome';
import styled from 'styled-components';

const MessageContainer = styled.div`
  padding-left: 5rem;
  width: 100%;
`;

function ContactUsScreen() {
  const sidebar = {
    title: __KA_SITE_TITLE__,
    description: `We'd love to hear from you!`,
  };

  // const formEl = useRef();
  // const useEffect(() => {
  //   formEl.current.inner
  // }, [formEl]);

  return (
    <SplashChrome title="Contact Us" sidebar={sidebar} header headerLogo={false}>
      <MessageContainer>
        <div dangerouslySetInnerHTML={{__html: `<div class="typeform-widget" data-url="https://form.typeform.com/to/Y2yYYNKJ?typeform-medium=embed-snippet" style="width: 100%; height: 500px;"></div> <script> (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })() </script>` }}></div>
      </MessageContainer>
    </SplashChrome>
  );
}

export default ContactUsScreen;
