import { BoxedPrimaryButton } from '../components/base/Buttons';
import { createSupportRequest } from '../api/help';
import { Form, FormActions, FormInput, FormLabel, FormTextArea } from '../components/base/Form';
import { H3 } from '../components/base/Headers';
import { useMutation } from 'react-query';
import { useState } from 'react';
import Loader from '../components/Loader';
import SplashChrome from '../components/SplashChrome';
import styled from 'styled-components';
import useFormInput from '../hooks/useFormInput';

function HelpButtons({ isSubmitting }) {
  if (isSubmitting) {
    return (
      <FormActions>
        <Loader />
      </FormActions>
    );
  }

  return (
    <FormActions>
      <BoxedPrimaryButton>Submit</BoxedPrimaryButton>
    </FormActions>
  );
}
// Thank you for your request! Please check your email for more information.

function HelpForm({ onSubmit, isSubmitting }) {
  const emailInput = useFormInput('');
  const subjectInput = useFormInput('');
  const bodyInput = useFormInput('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: emailInput.value,
      title: subjectInput.value,
      body: bodyInput.value,
    };

    onSubmit(data);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormLabel>&nbsp;</FormLabel>
      <H3>Please let us know how we can help</H3>
      <FormLabel htmlFor="email" required>
        <span>Your Email</span>
      </FormLabel>
      <FormInput id="email" maxLength="255" {...emailInput} />

      <FormLabel htmlFor="subject" required>
        <span>Subject</span>
      </FormLabel>
      <FormInput id="subject" maxLength="255" {...subjectInput} />

      <FormLabel htmlFor="body" required>
        <span>Message</span>
      </FormLabel>
      <FormTextArea
        id="body"
        placeholder="Please describe the problem you are having"
        {...bodyInput}
      />
      <HelpButtons isSubmitting={isSubmitting} />
    </Form>
  );
}

const MessageContainer = styled.div`
  padding-left: 5rem;
  width: 100%;
`;

function SubmittedMessage() {
  return (
    <MessageContainer>
      <p>Thank you for your request! Please check your email for more information.</p>
    </MessageContainer>
  );
}

function HelpChrome({ onSubmit, isSubmitting, isSubmitted }) {
  const sidebar = {
    title: __KA_SITE_TITLE__,
    description: __KA_SITE_HELP_TEXT__,
  };

  const content = isSubmitted ? (
    <SubmittedMessage />
  ) : (
    <HelpForm onSubmit={onSubmit} isSubmitting={isSubmitting} />
  );
  return (
    <>
      <SplashChrome title="Help" sidebar={sidebar} header headerLogo={false}>
        {content}
      </SplashChrome>
    </>
  );
}

function HelpScreen() {
  /* -- Hooks -- */
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitForm, { isLoading: isSubmitting }] = useMutation(createSupportRequest);

  const handleLogin = (data) => {
    submitForm(data, {
      onSuccess: () => {
        setIsSubmitted(true);
      },
    });
  };

  return (
    <HelpChrome onSubmit={handleLogin} isSubmitting={isSubmitting} isSubmitted={isSubmitted} />
  );
}

export default HelpScreen;
