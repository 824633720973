import decode from 'jwt-decode';

const key = 'truthandlove-auth';

function setToken(token) {
  localStorage.setItem(key, token);
}

function clearToken() {
  localStorage.removeItem(key);
}

function rawToken() {
  return localStorage.getItem(key);
}

function getDecodedToken() {
  const token = rawToken();
  if (!token || typeof token === 'undefined') {
    return null;
  }

  return decode(token);
}

function isAuthenticated() {
  const token = getDecodedToken();
  return !!token;
}

function currentUser() {
  const user = getDecodedToken();
  if (!user) {
    return null;
  }

  const expirationDate = new Date();
  expirationDate.setMilliseconds(user.iat);
  if (expirationDate <= Date.now()) {
    return null;
  }

  return user;
}

function hasPermission(permission) {
  const user = currentUser();
  if (!user || !user.permissions) {
    return false;
  }

  return user.permissions.indexOf(permission) !== -1;
}

export { clearToken, setToken, hasPermission, isAuthenticated, currentUser, rawToken };
