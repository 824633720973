import { fetchCourses } from '../api/courses';
import { useParams } from 'react-router';
import AsyncComponent from '../components/AsyncComponent';
import Chrome from '../components/Chrome';
import NotFound from './NotFound';
import UnitListing from '../components/courses/UnitListing';

function ViewCourse({ course }) {
  const breadcrumbs = [{ text: 'My Courses', url: '/my-courses' }, { text: course.title }];

  return (
    <>
      <Chrome title={course.title} breadcrumbs={breadcrumbs}>
        <UnitListing course={course} units={course.units} />
      </Chrome>
    </>
  );
}

function ViewCourseScreen() {
  /* -- Hooks -- */
  const { courseId } = useParams();
  return (
    <AsyncComponent
      executeQuery={fetchCourses}
      cacheKey="courses"
      render={(courses) => {
        const course = courses.find((_) => _.key === courseId);
        if (!course) {
          return <NotFound />;
        }

        return <ViewCourse course={course} />;
      }}
    />
  );
}

export default ViewCourseScreen;
