import { A } from '@bootstrap-styled/v4';
import { WaveLoading } from 'styled-spinkit';
import StreamItem from './StreamItem';
import styled from 'styled-components';

const StreamContainer = styled.div`
  @media screen and (max-width: 1024px) {
    height: calc((101vh / 2) - 100px);
  }

  margin-bottom: 15rem;

  &.replies {
    margin-left: 3rem;
    margin-bottom: 0;
  }
`;

const LoadLink = styled(A)`
  cursor: pointer;
`;

function LoadMore({ stream, onLoadNext, isLoading, loadMoreText }) {
  if (isLoading) {
    return <WaveLoading color="var(--primary)" size={40} />;
  }

  if (!stream.next && (typeof stream.totalCount === 'undefined' || stream.totalCount === 0)) {
    return null;
  }

  const viewPreviousClickHandler = (e) => {
    e.preventDefault();

    onLoadNext();
  };

  return <LoadLink onClick={viewPreviousClickHandler}>{loadMoreText}</LoadLink>;
}

function Stream({
  contextId,
  parent,
  stream,
  onLoadNext,
  isLoading,
  loadMoreText,
  deleteItem,
  isDeleting,
}) {
  const classes = parent ? 'replies' : 'comments';
  return (
    <StreamContainer className={classes}>
      {stream.items.map((item) => (
        <StreamItem
          key={item.id}
          contextId={contextId}
          parent={parent}
          item={item}
          deleteItem={deleteItem}
          isDeleting={isDeleting}
          streamComponent={Stream}
        />
      ))}
      <LoadMore
        stream={stream}
        onLoadNext={onLoadNext}
        isLoading={isLoading}
        loadMoreText={loadMoreText}
      />
    </StreamContainer>
  );
}

Stream.defaultProps = {
  loadMoreText: 'View previous comments...',
  isDeleting: false,
};

export default Stream;
