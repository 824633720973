import { Card, Col } from '@bootstrap-styled/v4';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CourseThumbnail = styled.img`
  width: 100%;
`;

export default function CourseListingItem({ course }) {
  return (
    <Col md={4}>
      <Card>
        <Link to={`/my-courses/${course.key}`}>
          <CourseThumbnail src={course.thumbnail} />
        </Link>
      </Card>
    </Col>
  );
}
