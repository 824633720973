import { BoxedPrimaryLink } from '../components/base/Buttons';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import { H2, H3 } from '../components/base/Headers';
import Chrome from '../components/Chrome';
import Hero from '../components/base/Hero';
import styled from 'styled-components';

const Section = styled.section`
  padding: 1rem;
`;

const Banner = styled(Section)`
  background-color: var(--tertiary);
  padding: 1rem;
`;

const HeroImage = styled.img`
  width: 100%;
`;

const Shell = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: stretch;
`;

const Right = styled.div`
  flex: 6;
`;

const Left = styled.div`
  flex: 4;
`;

const HeroTextContainer = styled.div`
  padding-top: 5rem;
`;

const HeroSubHeader = styled(H3)`
  text-transform: uppercase;
  color: #fff;
  font-size: 1rem;
`;

const HeroHeader = styled(H2)`
  text-transform: uppercase;
  color: #fff;
`;

const HeroTagline = styled(H3)`
  text-transform: uppercase;
  color: #fff;
  font-size: 0.75rem;
`;

const HeroSummary = styled.p`
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #fff;
  padding-right: 2rem;
`;

const SectionImage = styled(HeroImage)`
  filter: drop-shadow(0 0 0.15rem var(--tertiary));
`;

const SectionTextContainer = styled.div`
  padding-left: 5rem;
`;

const SectionSubHeader = styled(H3)`
  text-transform: uppercase;
  font-size: 1rem;
`;

const SectionHeader = styled(H2)`
  text-transform: uppercase;
`;

const SectionSummary = styled.p`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-right: 2rem;
`;

const ListenButton = styled(BoxedPrimaryLink)`
  margin-bottom: 2rem;
`;

function HomeScreen() {
  /* -- Hooks -- */
  return (
    <Chrome title="Home" fullscreen>
      <Banner>
        <Hero>
          <Row>
            <Col md={6}>
              <HeroTextContainer>
                <HeroSubHeader>Welcome to</HeroSubHeader>
                <HeroHeader>I Am Truth And Love</HeroHeader>
                <HeroTagline>where Singleness + Intimacy = Family</HeroTagline>
                <HeroSummary>
                  We speak truth in love without compromising the gospel of the Kingdom.
                </HeroSummary>
                <ListenButton to="/podcast">Listen Now</ListenButton>
              </HeroTextContainer>
            </Col>
            <Col md={6}>
              <HeroImage src="/images/about.jpg" alt="Banner" />
            </Col>
          </Row>
        </Hero>
      </Banner>
      <Section id="about-us">
        <Hero mt={5}>
          <Row>
            <Col md={4}>
              <SectionImage src="/images/beau-shanna.jpg" alt="Beau and Shanna" />
            </Col>
            <Col>
              <SectionTextContainer>
                <SectionSubHeader>About Us</SectionSubHeader>
                <SectionHeader>Our Story</SectionHeader>
                <SectionSummary>
                  We started I Am Truth and Love because we are passionate about curbing the divorce
                  rate by taking family back to the original intent. Marriage is only as good as the
                  two people in it. We have been married for 8 1/2 years and have two beautiful
                  children, Brik and Quinn. We love learning and growing in our marriage and enjoy
                  sharing what we have found breakthrough in with other couples. Join us as we take
                  a deeper look into how to maximize the roles of man, woman, husband, wife, father
                  and mother!
                </SectionSummary>
              </SectionTextContainer>
            </Col>
          </Row>
        </Hero>
      </Section>
    </Chrome>
  );
}

export default HomeScreen;
