import 'animate.css/animate.min.css';
import 'react-notifications-component/dist/theme.css';
import * as Pages from '../pages';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { hasPermission, isAuthenticated } from '../api/authenticationContext';
import { ReactQueryConfigProvider } from 'react-query';
import BootstrapProvider from '@bootstrap-styled/provider/lib/BootstrapProvider';
import GlobalStyles from './GlobalStyles';
import ReactNotification from 'react-notifications-component';
import styled from 'styled-components';

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 1,
  },
};

const AppContainer = styled.div`
  min-height: 100vh;
`;

function ConditionalComponent({ component: Component, condition, redirectTo }) {
  return <>{condition() ? <Component /> : <Navigate to={redirectTo} replace />}</>;
}

function AuthenticatedComponent({ component }) {
  return (
    <ConditionalComponent component={component} condition={isAuthenticated} redirectTo="/login" />
  );
}

function AuthorizedComponent({ component, permission }) {
  return (
    <ConditionalComponent
      component={component}
      condition={() => isAuthenticated() && hasPermission(permission)}
      redirectTo="/login"
    />
  );
}

function App() {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <BrowserRouter>
        <GlobalStyles />
        <ReactNotification />
        <BootstrapProvider>
          <AppContainer>
            <Routes>
              <Route
                path="/my-courses"
                element={<AuthenticatedComponent component={Pages.MyCourses} />}
              />
              <Route
                path="/my-courses/:courseId"
                element={<AuthenticatedComponent component={Pages.ViewCourse} />}
              />
              <Route
                path="/my-courses/:courseId/units/:unitId"
                element={<AuthenticatedComponent component={Pages.ViewUnit} />}
              />
              <Route
                path="/my-courses/:courseId/units/:unitId/lessons/:lessonId"
                element={<AuthenticatedComponent component={Pages.ViewLesson} />}
              />
              <Route path="/login" element={<Pages.Login />} />
              <Route path="/forgot-password" element={<Pages.ForgotPassword />} />
              <Route path="/password-reset/:token" element={<Pages.ResetPassword />} />
              <Route path="/help" element={<Pages.Help />} />
              <Route path="/about" element={<Pages.About />} />
              <Route path="/shop" element={<Pages.Shop />} />
              <Route path="/contact" element={<Pages.Contact />} />
              <Route path="/register" element={<Pages.Register />} />
              <Route path="/podcast" element={<Pages.Podcast />} />
              <Route path="/podcast/:episodeId" element={<Pages.ViewPodcast />} />
              <Route path="/checkout/success" element={<Pages.CheckoutSuccess />} />
              <Route path="/checkout/canceled" element={<Pages.Shop />} />
              <Route path="/" element={<Pages.Home />} />
              <Route path="/*" element={<Pages.NotFound />} />
            </Routes>
          </AppContainer>
        </BootstrapProvider>
      </BrowserRouter>
    </ReactQueryConfigProvider>
  );
}

export default App;
