import display from './mixins/display';
import spacing from './mixins/spacing';
import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: var(--header-font);
  font-weight: 700;

  ${spacing}
`;

export const H2 = styled.h2`
  font-family: var(--header-font);
  font-size: 2rem;
  font-weight: 700;
  color: var(--default);

  ${spacing}
  ${display}
`;

export const H3 = styled.h3`
  font-family: var(--header-font);
  font-size: 1.5rem;
  font-weight: 400;

  ${spacing}
`;

export const H4 = styled.h4`
  font-family: var(--header-font);
  font-weight: 300;

  ${spacing}
`;

export const H5 = styled.h5`
  font-family: var(--header-font);
  font-weight: 300;

  ${spacing}
`;

export const H6 = styled.h6`
  font-family: var(--header-font);
  font-weight: 300;

  ${spacing}
`;
