import { Row } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import UnitListingItem from './UnitListingItem';

const UnitListingContainer = styled.div`
  margin-top: 2rem;
`;

export default function UnitListing({ course, units }) {
  return (
    <UnitListingContainer>
      <Row>
        {units.map((unit) => (
          <UnitListingItem course={course} unit={unit} key={unit.id} />
        ))}
      </Row>
    </UnitListingContainer>
  );
}
