import { Card, CardBlock, CardImg, CardText, Col } from '@bootstrap-styled/v4';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ListingCard = styled(Card)`
  margin-bottom: 2rem;
`;

export default function UnitListingItem({ course, unit }) {
  return (
    <Col md={4}>
      <ListingCard>
        <Link to={`/my-courses/${course.key}/units/${unit.key}`}>
          <CardImg top width="100%" src={unit.thumbnail} />
        </Link>
        <CardBlock>
          <CardText>{unit.lessons.length} lessons</CardText>
        </CardBlock>
      </ListingCard>
    </Col>
  );
}
