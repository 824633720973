import { H3 } from '../components/base/Headers';
import { Link } from 'react-router-dom';
import SplashChrome from '../components/SplashChrome';
import styled from 'styled-components';

const MessageContainer = styled.div`
  padding-left: 5rem;
  width: 100%;
`;

function NotFoundScreen() {
  const sidebar = {
    title: __KA_SITE_TITLE__,
    description: '',
  };

  return (
    <SplashChrome title="Not Found" sidebar={sidebar} header headerLogo={false}>
      <MessageContainer>
        <H3>Uh oh! Are you lost?</H3>
        <p>
          No worries, it happens to the best of us. We recommend checking for spelling errors or
          returning <Link to="/">Home</Link>
        </p>
      </MessageContainer>
    </SplashChrome>
  );
}

export default NotFoundScreen;
