import { BoxedPrimaryButton } from '../base/Buttons';
import { createComment } from '../../api/comments';
import { useMutation, useQueryCache } from 'react-query';
import { useState } from 'react';
import { WaveLoading } from 'styled-spinkit';
import styled from 'styled-components';
import Textarea from 'react-expanding-textarea';

const TextBox = styled(Textarea)`
  padding: 5px;
  display: block;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
  box-shadow: none;
  border: 1px solid #ced4da;
  border-radius: 3%;
  box-sizing: content-box;
  background-color: transparent;
  overflow-y: hidden;
  resize: none;
  width: calc(100% - 20px);
`;

const TextBoxWrapper = styled.div`
  flex: 4.5;
`;

const EditorContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  flex: 0.5;
`;

const Loader = styled(WaveLoading)`
  margin: 0;
`;

function EditorButton({ onCreate, isLoading }) {
  if (isLoading) {
    return <Loader color="var(--primary)" size={40} />;
  }

  return (
    <ButtonWrapper>
      <BoxedPrimaryButton onClick={(e) => onCreate(e)}>Post</BoxedPrimaryButton>
    </ButtonWrapper>
  );
}

function Editor({ createItem, isLoading, placeholder }) {
  const [body, setBody] = useState('');

  const createClickHandler = async (e) => {
    e.preventDefault();
    await createItem({ body });
    setBody('');
  };

  return (
    <EditorContainer>
      <TextBoxWrapper>
        <TextBox placeholder={placeholder} value={body} onChange={(e) => setBody(e.target.value)} />
      </TextBoxWrapper>
      <EditorButton onCreate={createClickHandler} isLoading={isLoading} />
    </EditorContainer>
  );
}

export default Editor;
