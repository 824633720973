import { Col, Container, Row } from '@bootstrap-styled/v4';
import Loader from './Loader';

function AsyncLoader() {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <Loader />
        </Col>
      </Row>
    </Container>
  );
}

export default AsyncLoader;
