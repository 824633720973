import { fetchCourses } from '../api/courses';
import { useParams } from 'react-router';
import AsyncComponent from '../components/AsyncComponent';
import Chrome from '../components/Chrome';
import LessonListing from '../components/courses/LessonListing';
import NotFound from './NotFound';

function ViewUnit({ course, unit }) {
  const breadcrumbs = [
    { text: 'My Courses', url: '/my-courses' },
    { text: course.title, url: `/my-courses/${course.key}` },
    { text: unit.title },
  ];

  return (
    <>
      <Chrome title={course.title} breadcrumbs={breadcrumbs}>
        <LessonListing course={course} unit={unit} lessons={unit.lessons} />
      </Chrome>
    </>
  );
}

function ViewUnitScreen() {
  /* -- Hooks -- */
  const { courseId, unitId } = useParams();
  return (
    <AsyncComponent
      executeQuery={fetchCourses}
      cacheKey="courses"
      render={(courses) => {
        const course = courses.find((_) => _.key === courseId);
        if (!course) {
          return <NotFound />;
        }

        const unit = course.units.find((_) => _.key === unitId);
        if (!unit) {
          return <NotFound />;
        }

        return <ViewUnit course={course} unit={unit} />;
      }}
    />
  );
}

export default ViewUnitScreen;
