import { deleteReply, fetchReplies } from '../../api/comments';
import { useMutation, useQueryCache } from 'react-query';

function ReplyStream({ contextId, item, streamComponent: Stream }) {
  /* -- Hooks -- */
  const { replies: stream } = item;

  const cache = useQueryCache();
  const [onLoadNext, { isLoading }] = useMutation(() => fetchReplies({ item, next: stream.next }), {
    onSuccess: (newStream) => {
      cache.cancelQueries(['comments', contextId]);
      cache.setQueryData(['comments', contextId], (old) => {
        if (!old) {
          return old;
        }

        const existingItem = old.items.find((_) => _.id === item.id);
        if (existingItem) {
          const { replies } = existingItem;
          existingItem.replies = {
            ...newStream,
            items: [...replies.items, ...newStream.items],
            load: null,
          };
        }

        return old;
      });
    },
  });

  const [deleteItem, { isDeleting }] = useMutation(
    (reply) => deleteReply({ contextId, commentId: item.id, replyId: reply.id }),
    {
      onSuccess: (replyId) => {
        cache.cancelQueries(['comments', contextId]);
        cache.setQueryData(['comments', contextId], (old) => {
          if (!old) {
            return old;
          }

          const existingItem = old.items.find((_) => _.id === item.id);
          if (existingItem) {
            const { replies } = existingItem;
            replies.items = replies.items.filter((_) => _.id !== replyId);
            replies.totalCount = replies.totalCount ? --replies.totalCount : 0;

            if (replies.totalCount === 0) {
              replies.load = null;
              replies.next = null;
            }
          }

          return old;
        });
      },
    },
  );

  return (
    <Stream
      contextId={contextId}
      deleteItem={deleteItem}
      isDeleting={isDeleting}
      parent={item}
      stream={stream}
      isLoading={isLoading}
      onLoadNext={onLoadNext}
      loadMoreText="View replies"
    />
  );
}

export default ReplyStream;
