import { BoxedPrimaryButton } from '../components/base/Buttons';
import { Col, Row } from '@bootstrap-styled/v4';
import { H2, H3 } from '../components/base/Headers';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import Chrome from '../components/Chrome';
import Hero from '../components/base/Hero';
import request from '../api/request';
import SelectPicker from '../components/base/SelectPicker';
import styled from 'styled-components';

const Section = styled.section`
  padding: 1rem;
`;

const Banner = styled(Section)`
  background-color: var(--tertiary);
  padding: 1rem;
`;

const HeroImage = styled.img`
  width: 100%;
`;

const HeroTextContainer = styled.div`
  padding-top: 5rem;
`;

const HeroSubHeader = styled(H3)`
  text-transform: uppercase;
  color: #fff;
  font-size: 1rem;
`;

const HeroHeader = styled(H2)`
  text-transform: uppercase;
  color: #fff;
`;

const HeroTagline = styled(H3)`
  text-transform: uppercase;
  color: #fff;
  font-size: 0.75rem;
`;

const HeroSummary = styled.p`
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #fff;
  padding-right: 2rem;
`;

const HeroText = styled.p`
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  color: #fff;
  padding-right: 2rem;
`;

const fetchCheckoutSession = async ({ quantity }) => {
  const stripe = await loadStripe(__STRIPE_PUBLISHABLE_KEY__);
  console.log(quantity);
  const response = await request
    .post(`checkout/sessions`, {
      json: {
        quantity,
        product: 'price_1IXCsnA0GJnL84S25kdSVALR',
      },
    })
    .json();

  return {
    ...response,
    stripe,
  };
};

function ShopScreen() {
  const [quantity, setQuantity] = useState(1);

  const purchaseClickHandler = async (e) => {
    e.preventDefault();
    const { sessionId, stripe } = await fetchCheckoutSession({
      quantity,
    });

    // When the customer clicks on the button, redirect them to Checkout.
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

    console.log(error);
  };

  const options = [];
  for (let i = 1; i < 10; i++) {
    options.push({ value: `${i}`, label: `${i}` });
  }

  const onQuantityChanged = (val) => {
    const number = parseInt(val.value, 10);
    setQuantity(isNaN(number) ? 1 : number);
  };

  /* -- Hooks -- */
  return (
    <Chrome title="Home" fullscreen>
      <Banner>
        <Hero>
          <Row>
            <Col md={6}>
              <HeroTextContainer>
                <HeroSubHeader>March 27, 2021 (10am - 2pm CST)</HeroSubHeader>
                <HeroHeader>Art Of Sex Worskhop</HeroHeader>
                <HeroSummary>
                  Find Breakthrough in your marriage and sex life in this one day workshop with Beau
                  and Shanna. Get the sex talk you never had and learn about what God says about
                  sex. $97 per ticket.
                </HeroSummary>
                <div style={{ marginBottom: '1rem' }}>
                  <HeroText>How many tickets would you like?</HeroText>
                  <SelectPicker options={options} onChange={onQuantityChanged} />
                </div>
                <BoxedPrimaryButton onClick={purchaseClickHandler}>Purchase</BoxedPrimaryButton>
              </HeroTextContainer>
            </Col>
            <Col md={6}>
              <HeroImage src="/images/artsexworkshop.jpg" alt="Banner" />
            </Col>
          </Row>
        </Hero>
      </Banner>
    </Chrome>
  );
}

export default ShopScreen;
