import { currentUser } from '../../api/authenticationContext';
import { ReplyFill, TrashFill } from '@styled-icons/bootstrap';
import { useState } from 'react';
import ReplyEditor from './ReplyEditor';
import ReplyStream from './ReplyStream';
import styled from 'styled-components';

const moment = require('moment');

const ItemContainer = styled.div`
  padding: 5px;
  margin-bottom: 10px;
`;

const Body = styled.div`
  padding: 10px;
  background-color: #ccc;
  border-radius: 5px;
`;

const H4 = styled.h4`
  display: inline;
`;

const Time = styled.p`
  margin-left: 5px;
  display: inline;
  font-style: italic;
  color: #666;
  font-size: 12px;
`;

const ReactionContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding-right: 1rem;
  justify-content: flex-end;

  & a {
    margin-left: 1rem;
  }
`;

function Replies({ contextId, parent, item, streamComponent }) {
  if (parent) {
    return <></>;
  }

  return <ReplyStream contextId={contextId} item={item} streamComponent={streamComponent} />;
}

function StreamItem({ contextId, item, parent, streamComponent, deleteItem, isDeleting }) {
  const user = currentUser();
  const { actor } = item;
  const [isReplying, setIsReplying] = useState(false);

  const handleReplyClick = (e) => {
    e.preventDefault();
    setIsReplying(true);
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    deleteItem(item);
  };

  return (
    <ItemContainer>
      <div>
        <H4>
          {actor.firstName} {actor.lastName}
        </H4>
        <Time>{moment(item.created).fromNow()}</Time>
      </div>
      <Body>
        <p>{item.body}</p>
      </Body>
      <ReactionContainer>
        {!parent && (
          <a href="#" onClick={handleReplyClick}>
            <ReplyFill size={14} />
          </a>
        )}
        {actor.id === user.id && !isDeleting && (
          <a href="#" onClick={handleDeleteClick}>
            <TrashFill size={14} />
          </a>
        )}
      </ReactionContainer>
      {isReplying && (
        <ReplyEditor contextId={contextId} item={item} onReplyAdded={() => setIsReplying(false)} />
      )}
      <Replies
        contextId={contextId}
        parent={parent}
        item={item}
        streamComponent={streamComponent}
      />
    </ItemContainer>
  );
}

export default StreamItem;
