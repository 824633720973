import 'video.js/dist/video-js.css';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import videojs from 'video.js/core';

const Video = styled.video`
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
`;

const VideoContainer = styled.div`
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  height: 50vh;
`;

function VideoPlayer(props) {
  const containerRef = useRef(null);
  const playerNode = useRef(null);
  const player = useRef(null);

  useEffect(() => {
    if (player.current) {
      return;
    }

    const options = {
      ...props,
      controlBar: {
        playToggle: {
          replay: false,
        },
        pictureInPictureToggle: false,
      },
    };

    player.current = videojs(playerNode.current, options, () => {
      player.current.volume(1);
    });
  });

  useEffect(() => {
    if (player.current && props.sources.length) {
      player.current.src(props.sources[0].src);
    }
  });

  return (
    <VideoContainer ref={containerRef}>
      <div data-vjs-player>
        <Video ref={playerNode} className="video-js" />
      </div>
    </VideoContainer>
  );
}

export default VideoPlayer;
