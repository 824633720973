import { store } from 'react-notifications-component';
import localize from './localize';

function addNotification({ contents, message, title, type, ...options }) {
  store.addNotification({
    animationIn: ['animate__animated', 'animate__slideInDown'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    container: 'top-right',
    contents,
    dismiss: { duration: 4000 },
    insert: 'top',
    message,
    title,
    type,
    ...options,
  });
}

function addErrorNotification(errors) {
  const message = (
    <div>
      {errors.map((e) => (
        <div key={e.label}>
          {e.label} - {e.message}
        </div>
      ))}
    </div>
  );

  addNotification({
    message,
    title: localize('Errors'),
    type: 'danger',
  });
}

export { addErrorNotification, addNotification };
