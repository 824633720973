import { H2 } from '../components/base/Headers';
import Chrome from '../components/Chrome';
import styled from 'styled-components';

const Shell = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: 'row';
  align-items: stretch;
`;

const Right = styled.div`
  flex: 6;
`;

const Left = styled.div`
  flex: 4;
`;

const Banner = styled.img`
  width: 100%;
`;

const ContentContainer = styled.div`
  padding-left: 3rem;
  padding-right: 3rem;
`;

const Heading = styled(H2)`
  margin-bottom: 2rem;
`;

function AboutScreen() {
  /* -- Hooks -- */
  return (
    <Chrome title="About">
      <Shell>
        <Left>
          <Banner src="/images/about.jpg" alt="Banner" />
        </Left>
        <Right>
          <ContentContainer>
            <Heading>We Are The Niewoehners</Heading>
            <p>Welcome to I Am Truth and Love where Singleness + Intimacy= Family. We are Beau and Shanna Niewoehner and we speak truth in love without compromising the gospel of the Kingdom. We started I Am Truth and Love becuase we are passionate about curbing the divorce rate by taking family back to the original intent. Marriage is only as good as the two people in it. We have been married for 8 1/2 years and have two beautiful children, Brik and Quinn. We love learning and growing in our marriage and enjoy sharing what we have found breakthrough in with other couples. Join us as we take a deeper look into how to maximize the roles of man, woman, husband, wife, father and mother!</p>
          </ContentContainer>
        </Right>
      </Shell>
    </Chrome>
  );
}

export default AboutScreen;
