import request from './request';

async function createSupportRequest({ title, email, body }) {
  return request
    .post(`support/cases`, {
      json: {
        title,
        email,
        body,
      },
    })
    .json();
}

export { createSupportRequest };
