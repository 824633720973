import { createReply } from '../../api/comments';
import { useMutation, useQueryCache } from 'react-query';
import Editor from './Editor';

function ReplyEditor({ contextId, item, onReplyAdded }) {
  const cache = useQueryCache();
  const [createItem, { isLoading }] = useMutation(
    ({ body }) => createReply({ create: item.replies.create, body }),
    {
      onSuccess: (replyAdded) => {
        cache.cancelQueries(['comments', contextId]);
        cache.setQueryData(['comments', contextId], (old) => {
          if (!old) {
            return old;
          }

          const existingItem = old.items.find((_) => _.id === item.id);
          if (existingItem) {
            const { replies } = existingItem;
            existingItem.replies = {
              items: [replyAdded, ...replies.items],
              totalCount: replies.totalCount ? ++replies.totalCount : 1,
            };
          }

          return old;
        });
      },
    },
  );

  const createItemHandler = async ({ body }) => {
    await createItem({ body });
    onReplyAdded();
  };

  return (
    <Editor isLoading={isLoading} createItem={createItemHandler} placeholder="Write a reply" />
  );
}

export default ReplyEditor;
