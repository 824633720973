import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  background-color: var(--neutral);
  border-radius: 0.75rem;
  border-width: 0px;
  color: var(--neutral-text);
  transition: all 0.25s;

  padding: ${({ size }) => {
    if (size === 'sm') return '0.5rem .75rem';

    return '0.75rem 1rem';
  }};

  :focus,
  :hover {
    background-color: var(--neutral--hover);
    cursor: pointer;
    outline: none;
  }

  :focus {
    box-shadow: 0 0 4px 2px var(--neutral);
  }

  :active {
    background-color: var(--neutral--active);
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: var(--neutral--disabled);
    color: var(--neutral-text--disabled);
  }
`;

const PrimaryButton = styled(Button)`
  background-color: var(--primary);
  color: var(--primary-text);

  :focus,
  :hover {
    background-color: var(--primary--hover);
  }

  :focus {
    box-shadow: 0 0 4px 2px var(--primary);
  }

  :active {
    background-color: var(--primary--active);
  }

  &[disabled] {
    background-color: var(--primary--disabled);
    color: var(--primary-text--disabled);
  }
`;

const SecondaryButton = styled(Button)`
  background-color: var(--secondary);
  color: var(--secondary-text);

  :focus,
  :hover {
    background-color: var(--secondary--hover);
  }

  :focus {
    box-shadow: 0 0 4px 2px var(--secondary);
  }

  :active {
    background-color: var(--secondary--active);
  }
`;

const DangerButton = styled(Button)`
  background-color: var(--danger);
  color: var(--danger-text);

  :focus,
  :hover {
    background-color: var(--danger--hover);
  }

  :focus {
    box-shadow: 0 0 4px 2px var(--danger);
  }

  :active {
    background-color: var(--danger--active);
  }
`;

const UnstyledButton = styled.button`
  background-color: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  padding: 0;
`;

const IconButton = styled(UnstyledButton)`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: ${({ height }) => height || '24px'};
  justify-content: center;
  position: relative;
  transition-duration: 0.15s;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: ${({ width }) => width || '24px'};
  z-index: 1;

  ::before {
    background-color: ${({ variant }) => {
      switch (variant) {
        case 'primary':
          return 'var(--primary--hover)';

        case 'secondary':
          return 'var(--secondary--hover)';

        case 'danger':
          return 'var(--danger--hover)';

        default:
          return 'var(--neutral--hover)';
      }
    }};

    border-radius: 50%;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(0);
    transition-duration: 0.15s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
  }

  :focus,
  :hover {
    ::before {
      opacity: 1;
      transform: scale(1.5);
    }
  }
`;

IconButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'danger']),
};

const BoxedWhiteButton = styled.button`
  background: transparent;
  color: #fff;
  display: inline-block;
  padding: 16px 43px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  border: 2px solid #fff;
  text-align: center;
  color: #fff !important;
  text-transform: capitalize;

  &:hover {
    background: #fff;
    color: #000 !important;
    border: 2px solid #fff;
  }

  &:focus {
    outline: none;
  }
`;

const BoxedPrimaryButton = styled.button`
  background: var(--primary);
  color: var(--primary-text);
  display: inline-block;
  padding: 16px 43px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  border: 2px solid var(--primary);
  text-align: center;
  color: var(--primary-text) !important;
  text-transform: capitalize;

  &:hover {
    background-color: var(--primary--hover);
    border: 2px solid var(--primary--hover);
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    border: 2px solid var(--primary--disabled);
    background-color: var(--primary--disabled);

  }
`;

const BoxedPrimaryLink = styled(Link)`
  background: var(--primary);
  color: var(--primary-text);
  display: inline-block;
  padding: 16px 43px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  border: 2px solid var(--primary);
  text-align: center;
  color: var(--primary-text) !important;
  text-transform: capitalize;

  &:hover {
    background-color: var(--primary--hover);
    border: 2px solid var(--primary--hover);
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    border: 2px solid var(--primary--disabled);
    background-color: var(--primary--disabled);

  }
`;

const BoxedSecondaryButton = styled.button`
  background: var(--secondary);
  color: var(--secondary-text);
  display: inline-block;
  padding: 16px 43px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  border: 2px solid var(--secondary);
  text-align: center;
  color: var(--secondary-text) !important;
  text-transform: capitalize;

  &:hover {
    background-color: var(--secondary--hover);
    color: #000 !important;
    border: 2px solid var(--secondary--hover);
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    border: 2px solid var(--secondary--disabled);
    background-color: var(--secondary--disabled);
    color: var(--secondary-text--disabled);
  }
`;

const BoxedPrimaryOutlineButton = styled.button`
  background-color: #fff;
  display: inline-block;
  padding: 16px 43px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  border: 2px solid var(--primary);
  text-align: center;
  color: var(--primary) !important;
  text-transform: capitalize;

  &:hover {
    background-color: var(--primary--hover);
    color: #000 !important;
    border: 2px solid var(--primary--hover);
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    border: 2px solid var(--primary--disabled);
    background-color: var(--primary--disabled);
    color: var(--primary-text--disabled);
  }
`;

export {
  Button,
  DangerButton,
  IconButton,
  PrimaryButton,
  SecondaryButton,
  UnstyledButton,
  BoxedWhiteButton,
  BoxedPrimaryButton,
  BoxedPrimaryLink,
  BoxedPrimaryOutlineButton,
  BoxedSecondaryButton,
};
