import { BoxedPrimaryButton, BoxedSecondaryButton } from '../components/base/Buttons';
import { fetchPasswordResetToken, resetPassword } from '../api/users';
import { Form, FormActions, FormInput, FormLabel } from '../components/base/Form';
import { H3 } from '../components/base/Headers';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import AsyncComponent from '../components/AsyncComponent';
import Loader from '../components/Loader';
import SplashChrome from '../components/SplashChrome';
import styled from 'styled-components';
import useFormInput from '../hooks/useFormInput';

const MessageContainer = styled.div`
  padding-left: 5rem;
  width: 100%;
`;

function ResetPasswordFormButtons({ isSubmitting }) {
  const navigate = useNavigate();
  const cancelClickHandler = (e) => {
    e.preventDefault();
    navigate('/login');
  };

  if (isSubmitting) {
    return (
      <FormActions>
        <Loader />
      </FormActions>
    );
  }

  return (
    <FormActions>
      <BoxedPrimaryButton>Submit</BoxedPrimaryButton>
      <BoxedSecondaryButton onClick={cancelClickHandler} type="button">
        Cancel
      </BoxedSecondaryButton>
    </FormActions>
  );
}

function ResetPasswordForm({ resetMode, onSubmit, isSubmitting, token }) {
  const passwordInput = useFormInput('');
  const confirmPasswordInput = useFormInput('');
  const navigate = useNavigate();
  const clickLoginHandler = (e) => {
    e.preventDefault();
    navigate('/login');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      token,
      password: passwordInput.value,
      confirmPassword: confirmPasswordInput.value,
    };

    onSubmit(data);
  };

  const sidebar = {
    title: __KA_SITE_TITLE__,
    description: __KA_SITE_DESCRIPTION__,
  };

  let resetContent = null;
  switch (resetMode) {
    case 'reset':
      resetContent = (
        <MessageContainer>
          <p>Your password has been reset! Please login with your new password.</p>
          <BoxedPrimaryButton onClick={clickLoginHandler}>Login</BoxedPrimaryButton>
        </MessageContainer>
      );
      break;

    default:
      resetContent = (
        <Form onSubmit={handleSubmit}>
          <FormLabel>&nbsp;</FormLabel>
          <H3>Create your new password</H3>
          <FormLabel>&nbsp;</FormLabel>
          <p>You need to have purchased a course before proceeding.</p>
          <FormLabel htmlFor="password" required>
            <span>Password</span>
          </FormLabel>
          <FormInput
            id="password"
            type="password"
            autoComplete="new-password"
            maxLength="255"
            {...passwordInput}
          />
          <FormLabel htmlFor="password" required>
            <span>Confirm Password</span>
          </FormLabel>
          <FormInput
            id="password"
            type="password"
            autoComplete="new-password"
            maxLength="255"
            {...confirmPasswordInput}
          />
          <ResetPasswordFormButtons isSubmitting={isSubmitting} />
        </Form>
      );
      break;
  }

  return (
    <>
      <SplashChrome title="Reset Password" sidebar={sidebar}>
        {resetContent}
      </SplashChrome>
    </>
  );
}

function ResetPasswordScreen({ token }) {
  /* -- Hooks -- */
  const [resetMode, setResetMode] = useState(token === null ? 'invalid' : 'default');
  const [resetPasswordHandler, { isLoading: isSubmitting }] = useMutation(resetPassword);

  /* -- Event Handlers -- */
  const handleReset = (data) => {
    resetPasswordHandler(data, {
      onSuccess: () => {
        setResetMode('reset');
      },
    });
  };

  return (
    <ResetPasswordForm
      token={token}
      resetMode={resetMode}
      onSubmit={handleReset}
      isSubmitting={isSubmitting}
    />
  );
}

function ResetPasswordContainer() {
  const { token } = useParams();

  return (
    <AsyncComponent
      executeQuery={() => fetchPasswordResetToken(token)}
      cacheKey="password-token"
      render={() => <ResetPasswordScreen token={token} />}
    />
  );
}

export default ResetPasswordContainer;
