import request from './request';

async function fetchEpisodes() {
  const { data } = await request
    .post(`tenant/query`, {
      json: {
        field: 'media',
      },
    })
    .json();

  return data;
}

export { fetchEpisodes };
