import { convertFromRaw, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { useCallback, useState } from 'react';

function useFormInput(initialValue, callback = () => {}) {
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback((e) => {
    if (typeof e?.target?.value === 'string') {
      setValue(e.target.value);
      callback(e.target.value);
    } else {
      setValue(e);
    }
  }, []);

  return { onChange, value };
}

function useFormCheckbox(initialValue) {
  const [checked, setChecked] = useState(initialValue);

  const onChange = useCallback((e) => setChecked(e.target.checked), []);

  return { onChange, checked };
}

function useTextEditor(initialValue) {
  const [editorState, setEditorState] = useState(
    initialValue
      ? EditorState.createWithContent(stateFromHTML(initialValue))
      : EditorState.createEmpty(),
  );
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback((state) => {
    const html = stateToHTML(convertFromRaw(state));
    setValue(html);
  }, []);

  const onEditorStateChange = useCallback((state) => {
    setEditorState(state);
  }, []);

  return { onChange, onEditorStateChange, value, editorState };
}

export default useFormInput;

export { useFormCheckbox, useTextEditor };
