import { Row } from '@bootstrap-styled/v4';
import LessonListingItem from './LessonListingItem';
import styled from 'styled-components';

const ListingContainer = styled.div`
  margin-top: 2rem;
`;

export default function LessonListing({ course, unit, lessons }) {
  return (
    <ListingContainer>
      <Row>
        {lessons.map((lesson) => (
          <LessonListingItem course={course} unit={unit} key={lesson.id} lesson={lesson} />
        ))}
      </Row>
    </ListingContainer>
  );
}
