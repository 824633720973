import { authenticateUser } from '../api/users';
import { BoxedPrimaryButton, BoxedSecondaryButton } from '../components/base/Buttons';
import { Form, FormActions, FormInput, FormLabel } from '../components/base/Form';
import { H3 } from '../components/base/Headers';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import Loader from '../components/Loader';
import SplashChrome from '../components/SplashChrome';
import useFormInput from '../hooks/useFormInput';

function RegisterButton() {
  const navigate = useNavigate();
  const createAccountClickHandler = (e) => {
    e.preventDefault();
    navigate('/register');
  };

  return (
    <>
      <FormLabel>&nbsp;</FormLabel>
      <H3>- or -</H3>
      <FormActions style={{ marginTop: '2rem' }}>
        <BoxedSecondaryButton onClick={createAccountClickHandler} type="button">
          Create An Account
        </BoxedSecondaryButton>
      </FormActions>
    </>
  );
}

function LoginButtons({ isSubmitting }) {
  if (isSubmitting) {
    return (
      <FormActions>
        <Loader />
      </FormActions>
    );
  }

  return (
    <>
      <FormActions style={{ marginBottom: '2rem' }}>
        <BoxedPrimaryButton>Login</BoxedPrimaryButton>
        <Link to="/forgot-password">I Forgot My Password</Link>
      </FormActions>
      <RegisterButton />
    </>
  );
}

function LoginForm({ onSubmit, isSubmitting }) {
  const emailInput = useFormInput('');
  const passwordInput = useFormInput('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: emailInput.value,
      password: passwordInput.value,
    };

    onSubmit(data);
  };

  const sidebar = {
    title: __KA_SITE_TITLE__,
    description: __KA_SITE_DESCRIPTION__,
  };

  return (
    <>
      <SplashChrome title="Login" sidebar={sidebar}>
        <Form onSubmit={handleSubmit}>
          <FormLabel>&nbsp;</FormLabel>
          <H3>Login to access your library.</H3>
          <FormLabel>&nbsp;</FormLabel>
          <p>You need to have purchased a course before proceeding.</p>
          <FormLabel htmlFor="title" required>
            <span>Email</span>
          </FormLabel>
          <FormInput id="email" maxLength="255" {...emailInput} />

          <FormLabel htmlFor="password" required>
            <span>Password</span>
          </FormLabel>
          <FormInput
            id="password"
            type="password"
            autoComplete="current-password"
            maxLength="255"
            {...passwordInput}
          />
          <LoginButtons isSubmitting={isSubmitting} />
        </Form>
      </SplashChrome>
    </>
  );
}

function LoginScreen() {
  /* -- Hooks -- */
  const navigate = useNavigate();
  const [authenticate, { isLoading: isSubmitting }] = useMutation(authenticateUser);

  const handleLogin = (data) => {
    authenticate(data, {
      onSuccess: () => {
        navigate(`/my-courses`);
      },
    });
  };

  return <LoginForm onSubmit={handleLogin} isSubmitting={isSubmitting} />;
}

export default LoginScreen;
