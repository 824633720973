import { Col, Row } from '@bootstrap-styled/v4';
import CourseListingItem from './CourseListingItem';
import styled from 'styled-components';

const CourseListingContainer = styled.div`
  margin-top: 2rem;
`;

export default function CourseListing({ courses }) {
  return (
    <CourseListingContainer>
      <Row>
        {courses.length !== 0 &&
          courses.map((course) => <CourseListingItem course={course} key={course.id} />)}
        {!courses.length && (
          <Col md={12}>
            <p>You do not have access to any courses yet.</p>
          </Col>
        )}
      </Row>
    </CourseListingContainer>
  );
}
