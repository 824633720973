import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Row,
} from '@bootstrap-styled/v4';
import { Link } from 'react-router-dom';
import { MenuButton } from '@styled-icons/bootstrap';
import { useState } from 'react';
import HeaderLinks from './HeaderLinks';
import styled from 'styled-components';

const KaBreadcrumb = styled(Breadcrumb)`
  margin-top: 2rem !important;
`;

const Logo = styled.img`
  width: 150px;

  @media screen and (max-width: 1024px) {
    width: 100px;
  }
`;

const HeaderNav = styled(Navbar)`
  padding-top: 2rem !important;
`;

const MenuToggle = styled(NavbarToggler)`
  padding: 1rem !important;
  border: 1px solid var(--neutral) !important;
  align-self: flex-end !important;
`;

function BreadcrumbContent({ breadcrumb }) {
  if (!breadcrumb.url) {
    return <span>{breadcrumb.text}</span>;
  }

  return <Link to={breadcrumb.url}>{breadcrumb.text}</Link>;
}

function Breadcrumbs({ breadcrumbs }) {
  if (!breadcrumbs || !breadcrumbs.length) {
    return <></>;
  }

  return (
    <KaBreadcrumb>
      {breadcrumbs.map((breadcrumb, i) => {
        const isLast = i === breadcrumbs.length - 1;
        return (
          <BreadcrumbItem active={isLast} key={breadcrumb.text}>
            <BreadcrumbContent breadcrumb={breadcrumb} />
          </BreadcrumbItem>
        );
      })}
    </KaBreadcrumb>
  );
}

function Header({ breadcrumbs, logo }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <header>
        <HeaderNav toggleable="lg">
          <Container>
            <div className="d-inline-flex">
              <NavbarBrand tag={Link} to="/">
                {logo && <Logo src={__KA_TENANT_LOGO_URL__} alt="Logo" />}
              </NavbarBrand>
              <MenuToggle onClick={() => setIsOpen(!isOpen)} right>
                Menu <MenuButton size={18} />
              </MenuToggle>
            </div>
            <HeaderLinks isOpen={isOpen} />
          </Container>
        </HeaderNav>
      </header>
      {/* <Container>
        <Row>
          <Col md={12}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </Col>
        </Row>
      </Container> */}
    </>
  );
}

export default Header;
