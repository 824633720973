import { Col, Container, Row } from '@bootstrap-styled/v4';
import { Helmet } from 'react-helmet';
import buildTitle from '../utils/buildTitle';
import Header from './header/Header';
import localize from '../utils/localize';
import styled from 'styled-components';

const PageContainer = styled(Container)`
  ${(props) => {
    if (props.fluid) {
      return `
        padding-left: 0;
        padding-right: 0;
      `;
    }

    return null;
  }}
`;

function Chrome({ breadcrumbs, children, title, fullscreen = false }) {
  return (
    <>
      <Helmet>
        <title>{buildTitle(localize(title))}</title>
      </Helmet>
      <Header breadcrumbs={breadcrumbs} logo />
      <PageContainer fluid={fullscreen}>
        <Row noGutters={fullscreen}>
          <Col md={12}>{children}</Col>
        </Row>
      </PageContainer>
    </>
  );
}

export default Chrome;
