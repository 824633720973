import { useQuery } from 'react-query';
import AsyncLoader from './AsyncLoader';
import NotFound from '../pages/NotFound';
import QueryStatus from '../utils/QueryStatus';

function AsyncComponent({ cacheKey, executeQuery, renderLoader, render, queryOptions }) {
  /* -- Hooks -- */
  const { data, status, error } = useQuery(cacheKey, () => executeQuery(), queryOptions);

  /* -- Event Handlers -- */
  /* -- Rendering -- */
  switch (status) {
    case QueryStatus.ERROR: {
      if (error.statusCode === 404) {
        return <NotFound />;
      }

      return <div>error</div>;
    }

    case QueryStatus.LOADING:
      return renderLoader();

    default:
      return render(data);
  }
}

AsyncComponent.defaultProps = {
  renderLoader: () => <AsyncLoader />,
  queryOptions: { retry: false },
};

export default AsyncComponent;
