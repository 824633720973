module.exports = {
  tenantId: 'YiPYQ4mG',
  stripe: {
    // STRIPE_PUBLISHABLE_KEY: 'pk_test_51IIn4cA0GJnL84S205AEeH02sscwjwVMHP137nOPYT5SLB37i7FKzEQp8b3dxCKRLrrp8cSqtJiH6zPyMl0pazwb00Q5HDKloX',
    STRIPE_PUBLISHABLE_KEY:
      'pk_live_51IIn4cA0GJnL84S2V11hCv7V2EhOGeMnL6hHgWsfIq7YZ2lX7WJdyGmEdHKNYSS2EW0kymamlkaXXHviTCrFyQZm00fROQ40nb',
  },
  api: {
    root: 'https://1rztkf5yzg.execute-api.us-east-1.amazonaws.com/prod/',
  },
  settings: {
    title: 'I Am Truth And Love',
    description:
      'Welcome to I Am Truth &amp; Love where Singleness + Intimacy = Family. Beau and Shanna speak the truth in love without compromising the gospel of the Kingdom. They are passionate about curbing the divorce rate by taking family back to the original intent. Marriage is only as good as the two people in it. Join them as they take a deeper look into to how to maximize the roles of man, woman, husband, wife, father and mother!',
    helpText:
      'Welcome to I Am Truth and Love. If you are having issues accessing your library, please fill out our form and a member of our team will connect with you as soon as possible.',
    logo: 'https://truth-and-love-assets-prod.s3.amazonaws.com/logo-full.png',
    splashLogo: 'https://www.yourdiypodcastcourse.com/assets/img/splash.png',
  },
  variables: {
    colors: {
      default: `#000;`,
      'default--hover': `#e9ecef;`,
      'default-text': `#495057;`,
      danger: `hsl(0, 91%, 66%);`,
      'danger--active': `hsl(0, 100%, 38%);`,
      'danger--hover': `hsl(0, 100%, 57%);`,
      'danger-text': `#fff;`,
      neutral: `#ccc;`,
      'neutral--active': `hsl(211, 9%, 74%);`,
      'neutral--hover': `#e0e3e6;`,
      'neutral-text': `hsl(0, 0%, 13%);`,
      primary: `#73C6AA;`,
      'primary--active': `rgb(33, 121, 118);`,
      'primary--disabled': `#ccc;`,
      'primary--hover': `#7fb7ba;`,
      'primary-text': `#3F4343;`,
      'primary-text--disabled': `hsl(0, 0%, 100%, .8);`,
      'primary-transparent': `rgba(156, 225, 229, 0.1);`,
      secondary: `#ccc;`,
      'secondary--hover': `#999;`,
      'box-shadow--1': `4px 4px 30px 0px rgba(75, 102, 171, 0.2);`,
      'bg-color': `#f7f7f7;`,
      'font-family--body': `"Montserrat", sans-serif;`,
      'font-family--header': `"Montserrat", sans-serif;`,
      tertiary: `#3f4343;`,
      header: `var(--default);`,
      'header-font': `var(--font-family--header);`,
      'header-text': `var(--default-text);`,
      'input-bg-color': `#fff;`,
      'input-border-color': `#c1ccd3;`,
      'input-color': `var(--default-text);`,
      'link-color': `#3f4343;`,
      'link-color--hover': `#165c82;`,
      'table-border': `#d3d9df;`,
      'table-hover': `#e9ecef;`,
      'table-striped': `#f6f7f8;`,
      'animate-duration': `.5s;`,
      'sidebar-header-color': `#fff;`,
    },
  },
};
