import request from './request';

async function fetchComments({ contextId, last }) {
  let searchParams;
  if (last) {
    searchParams = {
      last,
    };
  }

  return request
    .get(`comments/${contextId}`, {
      searchParams,
    })
    .json();
}

async function fetchReplies({ item, next }) {
  const url = item && item.replies ? item.replies.load : next;
  if (!url) {
    return {
      items: [],
    };
  }

  return request.get(url).json();
}

async function createComment({ contextId, body }) {
  return request
    .post(`comments/${contextId}`, {
      json: {
        body,
      },
    })
    .json();
}

async function createReply({ create, body }) {
  return request
    .post(create, {
      json: {
        body,
      },
    })
    .json();
}

async function deleteComment({ contextId, commentId }) {
  await request.delete(`comments/${contextId}/${commentId}`);
  return commentId;
}

async function deleteReply({ contextId, commentId, replyId }) {
  await request.delete(`comments/${contextId}/${commentId}/replies/${replyId}`);
  return replyId;
}

export { createComment, createReply, deleteComment, deleteReply, fetchComments, fetchReplies };
