import { BoxedPrimaryButton, BoxedSecondaryButton } from '../components/base/Buttons';
import { Form, FormActions, FormInput, FormLabel } from '../components/base/Form';
import { H3 } from '../components/base/Headers';
import { requestPasswordResetToken } from '../api/users';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Loader from '../components/Loader';
import SplashChrome from '../components/SplashChrome';
import styled from 'styled-components';
import useFormInput from '../hooks/useFormInput';

const MessageContainer = styled.div`
  padding-left: 5rem;
  width: 100%;
`;

function ForgotPasswordFormButtons({ isSubmitting }) {
  const navigate = useNavigate();
  const cancelClickHandler = (e) => {
    e.preventDefault();
    navigate('/login');
  };

  if (isSubmitting) {
    return (
      <FormActions>
        <Loader />
      </FormActions>
    );
  }

  return (
    <FormActions>
      <BoxedPrimaryButton>Submit</BoxedPrimaryButton>
      <BoxedSecondaryButton onClick={cancelClickHandler} type="button">
        Cancel
      </BoxedSecondaryButton>
    </FormActions>
  );
}

function ForgotPasswordForm({ resetMode, onSubmit, isSubmitting }) {
  const emailInput = useFormInput('');
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: emailInput.value,
    };

    onSubmit(data);
  };

  const sidebar = {
    title: __KA_SITE_TITLE__,
    description: __KA_SITE_DESCRIPTION__,
  };

  let resetContent = null;
  switch (resetMode) {
    case 'reset':
      resetContent = (
        <MessageContainer>
          <p>
            Please check your email and follow the instructions to finish resetting your password.
          </p>
        </MessageContainer>
      );
      break;

    default:
      resetContent = (
        <Form onSubmit={handleSubmit}>
          <FormLabel>&nbsp;</FormLabel>
          <H3>Forgot Your Password?</H3>
          <FormLabel>&nbsp;</FormLabel>
          <p>You need to have purchased a course before proceeding.</p>
          <FormLabel htmlFor="title" required>
            <span>Email</span>
          </FormLabel>
          <FormInput id="email" maxLength="255" {...emailInput} />
          <ForgotPasswordFormButtons isSubmitting={isSubmitting} />
        </Form>
      );
      break;
  }

  return (
    <>
      <SplashChrome title="Forgot Password" sidebar={sidebar}>
        {resetContent}
      </SplashChrome>
    </>
  );
}

function ForgotPasswordScreen() {
  /* -- Hooks -- */
  const [resetMode, setResetMode] = useState('default');
  const [authenticate, { isLoading: isSubmitting }] = useMutation(requestPasswordResetToken);

  const handleLogin = (data) => {
    authenticate(data, {
      onSuccess: () => {
        setResetMode('reset');
      },
    });
  };

  return (
    <ForgotPasswordForm resetMode={resetMode} onSubmit={handleLogin} isSubmitting={isSubmitting} />
  );
}

export default ForgotPasswordScreen;
