import { createComment } from '../../api/comments';
import { useMutation, useQueryCache } from 'react-query';
import Editor from './Editor';

function CommentEditor({ contextId }) {
  const cache = useQueryCache();
  const [createItem, { isLoading }] = useMutation(
    ({ body }) => createComment({ contextId, body }),
    {
      onSuccess: (commentAdded) => {
        cache.cancelQueries(['comments', contextId]);
        cache.setQueryData(['comments', contextId], (old) => {
          if (!old) {
            return old;
          }

          const existing = old.items.find((_) => _.id === commentAdded.id);
          if (typeof existing !== 'undefined') {
            return old;
          }

          return {
            ...old,
            items: [commentAdded, ...old.items],
          };
        });
      },
    },
  );

  return <Editor isLoading={isLoading} createItem={createItem} placeholder="Write a comment" />;
}

export default CommentEditor;
