import { BoxedPrimaryButton } from '../base/Buttons';
import { useState } from 'react';
import PodcastEpisode from './PodcastEpisode';
import styled from 'styled-components';

const PAGE_SIZE = 5;

const ShowMoreButton = styled(BoxedPrimaryButton)`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

function PodcastList({ episodes }) {
  const [renderedEpisodes, setRenderedEpisodes] = useState(episodes.slice(0, PAGE_SIZE + 1));
  const [nrEpisodes, setNrEpisodes] = useState(PAGE_SIZE);
  const [allowShowMore, setAllowShowMore] = useState(true);

  const onShowMoreClicked = () => {
    const newNrEpisodes = nrEpisodes + PAGE_SIZE;
    setRenderedEpisodes(episodes.slice(0, newNrEpisodes + 1));
    setNrEpisodes(newNrEpisodes);
    setAllowShowMore(newNrEpisodes < episodes.length);
  };

  return (
    <>
      {renderedEpisodes.map((episode) => (
        <PodcastEpisode episode={episode} key={episode.url} />
      ))}
      {allowShowMore && <ShowMoreButton onClick={onShowMoreClicked}>Load More</ShowMoreButton>}
    </>
  );
}

export default PodcastList;
