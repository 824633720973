import localize from '../../utils/localize';

function I18n({ token }) {
  return <span>{localize(token)}</span>;
}

I18n.propTypes = {
  token: PropTypes.string.isRequired,
};

export default I18n;
