function localize(key, ...values) {
  // TODO: A stub for future localization
  let localization = key;

  if (!localization) {
    console.warn(`Localization not found: ${key}`);

    return key;
  }

  for (let i = 0; i <= values.length; i++) {
    const regex = new RegExp(`\\{${i}\\}`, 'g');

    localization = localization.replace(regex, values[i]);
  }

  return localization;
}

export default localize;
