import { Helmet } from 'react-helmet';
import { isAuthenticated } from '../api/authenticationContext';
import { Link } from 'react-router-dom';
import buildTitle from '../utils/buildTitle';
import Header from './header/Header';
import localize from '../utils/localize';
import styled from 'styled-components';

const Shell = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: 'row';
  align-items: stretch;
`;

const ContentCol = styled.div`
  flex: 7;
`;

const SidebarCol = styled.div`
  flex: 3;
  background: var(--tertiary);
`;

const SidebarLogoWrapper = styled.div`
  padding: 2rem;
`;

const SidebarLogo = styled.img`
  width: 100px;
`;

const SidebarHeader = styled.h2`
  color: var(--sidebar-header-color);
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 40px;
  text-transform: capitalize;
`;

const Description = styled.p`
  color: var(--sidebar-header-color);
`;

const SidebarContainer = styled.div`
  padding: 2rem;
  padding-top: 5rem;
  text-align: center;
`;

const ContentContainer = styled.div`
  padding-right: 10rem;
  padding-top: 15rem;
`;

const ContentWithHeaderContainer = styled.div`
  padding-right: 10rem;
  padding-top: 7.5rem;
`;

function ConditionalHeader({ show, showLogo }) {
  if (!show || !isAuthenticated()) {
    return <></>;
  }

  return <Header logo={showLogo} />;
}

function SplashChrome({ children, header, headerLogo, sidebar, title }) {
  const Container = header ? ContentWithHeaderContainer : ContentContainer;
  return (
    <>
      <Shell>
        <Helmet>
          <title>{buildTitle(localize(title))}</title>
        </Helmet>
        <SidebarCol>
          <SidebarLogoWrapper>
            <Link to="/">
              <SidebarLogo src={__KA_TENANT_LOGO_URL__} />
            </Link>
          </SidebarLogoWrapper>
          <SidebarContainer>
            <SidebarHeader>{sidebar.title}</SidebarHeader>
            <Description>{sidebar.description}</Description>
          </SidebarContainer>
        </SidebarCol>
        <ContentCol>
          <ConditionalHeader show={header} showLogo={headerLogo} />
          <Container>{children}</Container>
        </ContentCol>
      </Shell>
    </>
  );
}

SplashChrome.defaultProps = {
  headerLogo: true,
};

export default SplashChrome;
