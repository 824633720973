import { canonicalEpisodeUrl } from '../components/podcasts/PodcastEpisode';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import { fetchEpisodes } from '../api/podcasts';
import { H2, H3 } from '../components/base/Headers';
import { useParams } from 'react-router';
import AsyncComponent from '../components/AsyncComponent';
import AudioPlayer from 'react-h5-audio-player';
import Chrome from '../components/Chrome';
import NotFound from './NotFound';
import parse from 'html-react-parser';
import styled from 'styled-components';

import 'react-h5-audio-player/lib/styles.css';

const PodcastImage = styled.img`
  width: 80%;
`;

const SummaryCol = styled.div`
  flex: 7;
  padding-left: 3rem;
`;

const ImageCol = styled.div`
  flex: 3;
  text-align: center;

  & img {
    width: 100%;
  }
`;

const PodcastHeader = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: stretch;
  margin-top: 3rem;
  padding-bottom: 3rem;
`;

const PodcastH2 = styled(H2)``;

const PodcastH3 = styled(H3)`
  font-style: italic;
`;

const PodcastSummary = styled.div`
  margin-top: 1rem;

  & ul {
    padding-left: 3rem;
  }
`;

function PodcastPlayer({ episode }) {
  return <AudioPlayer autoPlay src={episode.url} />;
}

function ViewPodcastEpisode({ episode }) {
  return (
    <Chrome title="Podcast">
      <Row>
        <Col md={4}>
          <PodcastImage
            src="https://is3-ssl.mzstatic.com/image/thumb/Podcasts113/v4/67/ef/77/67ef77cb-b63d-8b54-e4a0-8fdbc0cb15d7/mza_211257967817475318.png/536x0w.png"
            alt="Truth And Love"
          />
        </Col>
        <Col md={8}>
          <PodcastH2>{episode.title}</PodcastH2>
          <PodcastH3>I Am Truth And Love Podcast</PodcastH3>
          <PodcastSummary>{parse(episode.description)}</PodcastSummary>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <PodcastPlayer episode={episode} />
        </Col>
      </Row>
    </Chrome>
  );
}

function ViewPodcastScreen() {
  /* -- Hooks -- */
  const { episodeId } = useParams();
  return (
    <AsyncComponent
      executeQuery={fetchEpisodes}
      cacheKey="episodes"
      render={(episodes) => {
        const episode = episodes.find((_) => canonicalEpisodeUrl(_.title) === episodeId);
        if (!episode) {
          return <NotFound />;
        }

        return <ViewPodcastEpisode episode={episode} />;
      }}
    />
  );
}

export default ViewPodcastScreen;
