import { addErrorNotification } from '../utils/notifications';
import { isAuthenticated, rawToken } from './authenticationContext';
import ky from 'ky';

const authHandler = (request) => {
  if (!isAuthenticated()) {
    return;
  }

  request.headers.set('authorization', `Bearer ${rawToken()}`);
};

class HTTPError extends Error {
  constructor(statusCode) {
    super();
    this.statusCode = statusCode;
  }
}

const errorHandler = async (_request, _options, response) => {
  switch (response.status) {
    case 400: {
      const json = await response.json();

      addErrorNotification(json.errors);

      break;
    }

    case 404: {
      throw new HTTPError(404);
    }

    default:
  }

  return response;
};

export default ky.create({
  retry: 0,
  hooks: {
    beforeRequest: [authHandler],
    afterResponse: [errorHandler],
  },
  headers: {
    'X-KA-Tenant': __KA_TENANT__,
  },
  prefixUrl: __KA_API_URL__,
});
