import { Col, Container, Row } from '@bootstrap-styled/v4';
import { fetchEpisodes } from '../api/podcasts';
import { H2, H3 } from '../components/base/Headers';
import { useQuery } from 'react-query';
import AsyncLoader from '../components/AsyncLoader';
import Chrome from '../components/Chrome';
import Hero from '../components/base/Hero';
import PodcastList from '../components/podcasts/PodcastList';
import QueryStatus from '../utils/QueryStatus';
import styled from 'styled-components';

const Section = styled.section`
  padding: 1rem;
`;

const HeroImage = styled.img`
  width: 80%;
`;

const HeroTextContainer = styled.div`
  padding-top: 5rem;
`;

const HeroSubHeader = styled(H3)`
  text-transform: uppercase;
  font-size: 1rem;
`;

const HeroHeader = styled(H2)`
  text-transform: uppercase;
`;

const HeroTagline = styled(H3)`
  text-transform: uppercase;
  font-size: 0.75rem;
`;

const HeroSummary = styled.p`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-right: 2rem;
`;

function PodcastingListing() {
  const { data, status } = useQuery('podcast', () => fetchEpisodes(), { retry: false });

  /* -- Event Handlers -- */
  /* -- Rendering -- */
  switch (status) {
    case QueryStatus.ERROR: {
      return null;
    }

    case QueryStatus.LOADING:
      return <AsyncLoader />;

    default:
      return <PodcastList episodes={data || []} />;
  }
}

function HomeScreen() {
  /* -- Hooks -- */
  return (
    <Chrome title="Podcast" fullscreen>
      <Section>
        <Hero>
          <Row>
            <Col md={6}>
              <HeroImage
                src="https://is3-ssl.mzstatic.com/image/thumb/Podcasts113/v4/67/ef/77/67ef77cb-b63d-8b54-e4a0-8fdbc0cb15d7/mza_211257967817475318.png/536x0w.png"
                alt="Truth And Love"
              />
            </Col>
            <Col md={6}>
              <HeroTextContainer>
                <HeroSubHeader>Beau and Shanna Niewohner</HeroSubHeader>
                <HeroHeader>I Am Truth And Love Podcast</HeroHeader>
                <HeroTagline>where Singleness + Intimacy = Family</HeroTagline>
                <HeroSummary>
                  Welcome to I Am Truth &amp; Love where Singleness + Intimacy = Family. Beau and
                  Shanna speak the truth in love without compromising the gospel of the Kingdom.
                  They are passionate about curbing the divorce rate by taking family back to the
                  original intent. Marriage is only as good as the two people in it. Join them as
                  they take a deeper look into to how to maximize the roles of man, woman, husband,
                  wife, father and mother!
                </HeroSummary>
              </HeroTextContainer>
            </Col>
          </Row>
        </Hero>
      </Section>
      <Container>
        <Row>
          <Col md={12}>
            <PodcastingListing />
          </Col>
        </Row>
      </Container>
    </Chrome>
  );
}

export default HomeScreen;
