import { Col, Container, Row } from '@bootstrap-styled/v4';
import spacing from './mixins/spacing';
import styled from 'styled-components';

const ChildContainer = styled.div`
  ${spacing}
`;

function Hero({ children, ...props }) {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <ChildContainer {...props}>{children}</ChildContainer>
        </Col>
      </Row>
    </Container>
  );
}

export default Hero;
