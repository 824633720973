import request from './request';

async function fetchCourses() {
  const courses = await request.get(`my-courses`).json();
  if (!courses) {
    return [];
  }

  return courses;
}

async function markLessonComplete({ courseId, unitId, lessonId }) {
  return request.put(`courses/${courseId}/units/${unitId}/lessons/${lessonId}/complete`).json();
}

export { fetchCourses, markLessonComplete };
