import { H3 } from '../base/Headers';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import styled from 'styled-components';

const EpisodeContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #3f4343;
`;

const Title = styled(H3)``;

const Description = styled.div`
  margin-top: 1rem;
  color: #3f4343;

  & p {
    margin-top: 1rem;
  }

  & ul {
    padding-left: 3rem;
  }
`;

const Duration = styled.p`
  margin-top: 1rem;
  color: #3f4343;
  font-style: italic;
`;

function canonicalEpisodeUrl(title) {
  return title
    .replace(/\s/g, '-')
    .replace(/:/g, '-')
    .replace(/\./g, '-')
    .replace(/--/g, '-')
    .toLowerCase();
}

function PodcastEpisode({ episode }) {
  return (
    <EpisodeContainer>
      <Title>
        <Link to={`/podcast/${canonicalEpisodeUrl(episode.title)}`}>{episode.title}</Link>
      </Title>
      <Description>{parse(episode.description)}</Description>
      <Duration>{Math.round(episode.duration / 60)} min</Duration>
    </EpisodeContainer>
  );
}

export default PodcastEpisode;

export { canonicalEpisodeUrl };
