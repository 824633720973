import { BellFill, DoorOpenFill } from '@styled-icons/bootstrap';
import { BoxedPrimaryButton } from '../base/Buttons';
import { clearToken } from '../../api/authenticationContext';
import { Collapse, Nav, NavItem } from '@bootstrap-styled/v4';
import { Link, useNavigate } from 'react-router-dom';
import AuthenticationGuard from '../base/AuthenticationGuard';
import styled from 'styled-components';

const NavLink = styled(Link)`
  display: block;
  padding: 2rem 1.5em;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  text-decoration: none;
`;

const NavA = styled.a`
  display: block;
  padding: 2rem 1.5em;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  text-decoration: none;
`;

function HeaderLinks({ isOpen }) {
  const navigate = useNavigate();
  const logoutClickHandler = (e) => {
    e.preventDefault();
    clearToken();
    navigate('/');
  };

  const registerClickHandler = (e) => {
    e.preventDefault();
    navigate('/register');
  };

  return (
    <Collapse navbar isOpen={isOpen}>
      <Nav navbar className="ml-auto">
        <NavItem>
          <NavLink to="/">Home</NavLink>
        </NavItem>
        <NavItem>
          <NavA href="https://dev220511.typeform.com/to/Y2yYYNKJ" target="_blank">Contact</NavA>
        </NavItem>
        <NavItem>
          <NavLink to="/podcast">Podcast</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/shop">Shop</NavLink>
        </NavItem>
        <AuthenticationGuard>
          <AuthenticationGuard.Granted>
            <NavItem>
              <NavLink to="/my-courses">My Courses</NavLink>
            </NavItem>
          </AuthenticationGuard.Granted>
        </AuthenticationGuard>
        <AuthenticationGuard>
          <AuthenticationGuard.Granted>
            <NavItem>
              <NavLink to="/notifications">
                <BellFill size={18} />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/logout" onClick={logoutClickHandler}>
                <DoorOpenFill size={18} />
              </NavLink>
            </NavItem>
          </AuthenticationGuard.Granted>
        </AuthenticationGuard>
        <AuthenticationGuard>
          <AuthenticationGuard.Denied>
            <NavItem>
              {/* <BoxedPrimaryButton onClick={registerClickHandler}>Register</BoxedPrimaryButton> */}
              {/* <NavLink to="/register">Register Now</NavLink> */}
            </NavItem>
          </AuthenticationGuard.Denied>
        </AuthenticationGuard>
      </Nav>
    </Collapse>
  );
}

export default HeaderLinks;
