import { BoxedPrimaryButton } from '../components/base/Buttons';
import { Col, Row } from '@bootstrap-styled/v4';
import { H2, H3 } from '../components/base/Headers';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import Chrome from '../components/Chrome';
import Hero from '../components/base/Hero';
import request from '../api/request';
import SelectPicker from '../components/base/SelectPicker';
import styled from 'styled-components';

const Section = styled.section`
  padding: 1rem;
`;

const Banner = styled(Section)`
  background-color: var(--tertiary);
  padding: 1rem;
`;

const HeroImage = styled.img`
  width: 100%;
`;

const HeroTextContainer = styled.div`
  padding-top: 5rem;
`;

const HeroSubHeader = styled(H3)`
  text-transform: uppercase;
  color: #fff;
  font-size: 1rem;
`;

const HeroHeader = styled(H2)`
  text-transform: uppercase;
  color: #fff;
`;

const HeroTagline = styled(H3)`
  text-transform: uppercase;
  color: #fff;
  font-size: 0.75rem;
`;

const HeroSummary = styled.p`
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #fff;
  padding-right: 2rem;
`;

const HeroText = styled.p`
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  color: #fff;
  padding-right: 2rem;
`;

function CheckoutSuccessScreen() {
  /* -- Hooks -- */
  return (
    <Chrome title="Home" fullscreen>
      <Banner>
        <Hero>
          <Row>
            <Col md={6}>
              <HeroTextContainer>
                <HeroSubHeader>March 27, 2021 (10am - 2pm CST)</HeroSubHeader>
                <HeroHeader>Art Of Sex Worskhop</HeroHeader>
                <div style={{ marginBottom: '1rem' }}>
                  <HeroText>Thank you for purchasing!</HeroText>
                </div>
              </HeroTextContainer>
            </Col>
            <Col md={6}>
              <HeroImage src="/images/artsexworkshop.jpg" alt="Banner" />
            </Col>
          </Row>
        </Hero>
      </Banner>
    </Chrome>
  );
}

export default CheckoutSuccessScreen;
