import { createContext, useContext } from 'react';
import { isAuthenticated } from '../../api/authenticationContext';

const AuthenticationContext = createContext();

function AuthDenied({ children }) {
  const hasPermission = useContext(AuthenticationContext);

  if (hasPermission) return null;

  return children;
}

function AuthGranted({ children }) {
  const isGranted = useContext(AuthenticationContext);

  if (!isGranted) return null;

  return children;
}

function AuthenticationGuard({ children }) {
  return (
    <AuthenticationContext.Provider value={isAuthenticated()}>
      {children}
    </AuthenticationContext.Provider>
  );
}

AuthenticationGuard.Denied = AuthDenied;
AuthenticationGuard.Granted = AuthGranted;

AuthenticationGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticationGuard;
